import { ItemBayarPage } from './item_Bayar/ItemBayarPage';
import MakamPage from './makam/MakamPage';
import { SignPage } from './sign/SignPage';
import TPUPage from './tpu/TPUPage';

const MasterPage: Page = {
  path: '/master',
  title: 'Master Data',
  type: 'Route',
  pages: [TPUPage, MakamPage, ItemBayarPage, SignPage],
};

export default MasterPage;
