import { useCallback } from 'react';
import { useTypedSelector } from '../../../stores';
import useGetUrl from './useGetUrl';

const useGetForeignKey = () => {
  const { serverUrl } = useTypedSelector(s => s.basis);
  const [getFkItems] = useGetUrl<Generic>();

  const getForeignKey = useCallback((model: Model, id: string, callback: (data: Generic) => void) => {
    const url = `${serverUrl}/${model.name}/${id}`;
    getFkItems(url, (data) => {
      callback(data);
    });
  }, [getFkItems, serverUrl]);

  return { getForeignKey };
};

export default useGetForeignKey;
