import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import useGetForeignKey from '../../../basis/components/hooks/useGetForeignKey';
import useGetUrl from '../../../basis/components/hooks/useGetUrl';
import { useTypedSelector } from '../../../stores';
import { ItemBayar } from '../../master/item_Bayar/ItemBayar';
import { Sign } from '../../master/sign/Sign';
import TPU from '../../master/tpu/TPU';
import { Jenazah } from '../../pendaftaran/jenazah/Jenazah';
import styles from './suratpembayaran.module.css';

interface Props {
  data: any,
}

const Format: React.FC<Props> = ({ data }) => {
  const [mounted, setMounted] = useState(false);
  const [jenazah, setJenazah] = useState<Generic | null>(null);
  const [tpu, setTpu] = useState<Generic>();
  const [items, setItems] = useState<Generic[]>([]);
  const [ttds, setTtds] = useState<Generic[]>([]);

  const { getForeignKey } = useGetForeignKey();
  const { serverUrl } = useTypedSelector(s => s.basis);
  
  const [getTtd] = useGetUrl<Generic[]>();

  useEffect(() => {
    const url = `${serverUrl}/${Sign.name}`
    getTtd(url, data => setTtds(data));
  }, [getTtd, serverUrl]);

  useEffect(() => {
    getForeignKey(Jenazah, data['jenazah'], data => setJenazah(data));
  }, [data, getForeignKey]);

  useEffect(() => {
    if (jenazah == null) {
      return;
    }
    getForeignKey(TPU, jenazah['tpu'], data => setTpu(data))
  }, [getForeignKey, jenazah]);

  useEffect(() => {
    if (data == null || mounted) {
      return;
    }

    setMounted(true);
    data.item_bayar.forEach((item: Generic) => {
      getForeignKey(ItemBayar, item['item_bayar'], data => {
        setItems(items => {
          const newItems = [...items];

          const i = items.find(f => f._id === data._id && f.deskripsi === item.deskripsi);

          if (i == null) {
            newItems.push({ ...data, deskripsi: item.deskripsi });
          }

          return newItems;
        });
      });
    });
  }, [data, getForeignKey, mounted]);

  return (
    <div className={styles.root}>
      <div className={styles.kop}>
        <h4>PEMERINTAH KOTA SUKABUMI</h4>
        <h2>DINAS LINGKUNGAN HIDUP</h2>
        <h4>UPT PEMAKAMAN</h4>
        <p>Jl. Pemuda No. 71 Telp. (0266) 220077, Sukabumi</p>
      </div>
      <div className={styles.gb} />

      <h2>SURAT PEMBERITAHUAN PEMBAYARAN</h2>

      <div className={styles.nomor}>
        <div>Nomor: {data.no_surat}</div>
        <div>Tanggal: {format(new Date(data.tanggal), 'dd-MMM-yyyy', { locale: id })}</div>
      </div>

      <div className={styles.form}>
        <div>
          <div>No. Urut</div>
          <div>: {data.no_urut}</div>
        </div>

        <div>
          <div>Nama Alm.</div>
          <div>: {jenazah?.nama}</div>
        </div>

        <div>
          <div>Lokasi TPU</div>
          <div>: {tpu?.nama}</div>
        </div>

        <div>
          <div>Pemohon</div>
          <div>: {data.pemohon}</div>
        </div>

        <div>
          <div>Alamat</div>
          <div>: {data.alamat}</div>
        </div>

        <div>
          <div>Pekerjaan</div>
          <div>: {data.pekerjaan}</div>
        </div>

        <div>
          <div>NPWD</div>
          <div>: {data.npwpd}</div>
        </div>

        <div>
          <div>Telepon</div>
          <div>: {data.telepon}</div>
        </div>

        <div>
          <div>Masa Berlaku</div>
          <div>: -</div>
        </div>

      </div>

      <div className={styles.batas}>
        <div>Batas Penyetoran akhir tanggal: {format(new Date(data.tanggal_akhir_setor), 'dd-MMM-yyyy', { locale: id })}</div>
      </div>

      <div className={styles.garis} />
      <table border={1} cellSpacing={0} cellPadding={6}>
        <thead>
          <tr>
            <th style={{ width: '60pt' }}>No</th>
            <th style={{ width: '150pt' }}>Ayat</th>
            <th>Item</th>
            <th style={{ width: '200pt' }}>Jumlah Rp.</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: Generic, i: number) => {
            return (
              <React.Fragment key={i}>
                <tr>
                  <td style={{ textAlign: 'center' }}>{i+1}</td>
                  <td>{item.ayat}</td>
                  <td>{item.nama}</td>
                  <td>Rp. {item.tarif.toLocaleString('id-ID')},-</td>
                </tr>
                <tr>
                  <td colSpan={4}>{item.deskripsi}</td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>JUMLAH</td>
            <td style={{ fontWeight: 'bold' }}>Rp. {items.reduce((prev, item) => prev + item.tarif, 0).toLocaleString('id-ID')},-</td>
          </tr>
        </tfoot>
      </table>
      <div className={styles.garis} />

      <div className={styles.footer}>
        <div>Sukabumi, {format(new Date(data.tanggal), 'dd MMMM yyyy', { locale: id })}</div>
        <div style={{ fontWeight: 'bold' }}>DINAS LINGKUNGAN HIDUP</div>
        <div style={{ fontWeight: 'bold' }}>KOTA SUKABUMI</div>
        <div>An KEPALA UPT PEMAKAMAN</div>
        <div>Kasubbag Tata Usaha</div>
        <div style={{ textDecoration: 'underline #000', marginTop: '94pt', textTransform: 'uppercase' }}>
          &nbsp;{ttds.length > 0 ? ttds[0].nama : ''}&nbsp;
        </div>
        <div>NIP. {ttds.length > 0 ? ttds[0].nip : ''}</div>
      </div>
    </div>
  );
};

class SuratPembayaran extends React.PureComponent<Props> {
  render() {
    const { data } = this.props;

    if (data == null) {
      return (<></>);
    }

    return (
      <Format data={data} />
    );
  }
}

export default SuratPembayaran;
