export const Kremasi: Model = {
  name: 'kremasi',
  title: 'Kremasi',
  fields: [
    { name: 'no_surat', label: 'No Surat', type: 'string', searchable: true },
    { name: 'tempat', label: 'Tempat Kremasi', type: 'string' },
    { name: 'alamat', label: 'Alamat', type: 'text' },
    { name: 'no_pol', label: 'Nopol', type: 'string' },
    { name: 'jenazah', label: 'Jenazah', type: 'foreign_key', foreignKey: { model: 'jenazah', field: 'nama' } },
    { name: 'keterangan', label: 'Keterangan', type: 'text' },
  ],
};
