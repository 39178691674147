import SuratPembayaran from './SuratPembayaran';

const Item: Model = {
  name: 'pembayaran_item',
  title: 'Item Pembayaran',
  fields: [
    { name: 'item_bayar', label: 'Item Bayar', type: 'foreign_key', foreignKey: { model: 'item_bayar', field: 'nama' } },
    { name: 'deskripsi', label: 'Deskripsi', type: 'text' },
  ],
}

export const Pembayaran: Model = {
  name: 'pembayaran',
  title: 'Pembayaran',
  printFormat: SuratPembayaran,
  fields: [
    { name: 'no_urut', label: 'No Urut', type: 'auto_increment' },
    { name: 'no_surat', label: 'No Surat', type: 'string', searchable: true },
    { name: 'tanggal', label: 'Tanggal', type: 'date', defaultValue: new Date() },
    { name: 'periode', label: 'Periode', type: 'string' },
    { name: 'jenazah', label: 'Jenazah', type: 'foreign_key', foreignKey: { model: 'jenazah', field: 'nama' } },
    { name: 'pemohon', label: 'Pemohon', type: 'string', searchable: true },
    { name: 'alamat', label: 'Alamat', type: 'text', searchable: true, hideColumn: true },
    { name: 'telepon', label: 'Telepon', type: 'string' },
    { name: 'pekerjaan', label: 'Pekerjaan', type: 'string', hideColumn: true },
    { name: 'npwd', label: 'NPWD', type: 'string', hideColumn: true },
    { name: 'tanggal_akhir_setor', label: 'Tanggal Akhir Setor', type: 'date', defaultValue: new Date() },
    { name: 'item_bayar', label: 'Item Bayar', type: 'array', model: Item },
  ],
};
