import Makam from './Makam';

const MakamPage: Page = {
  path: '/makam',
  title: 'Makam',
  type: 'CRUDTable',
  model: Makam,
};

export default MakamPage;
