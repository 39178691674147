import TPU from './TPU';

const TPUPage: Page = {
  path: '/',
  title: 'TPU',
  type: 'CRUDTable',
  model: TPU,
};

export default TPUPage;
