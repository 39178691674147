const AhliWaris: Model = {
  name: 'ahli_waris',
  title: 'Ahli Waris',
  fields: [
    { name: 'nama', label: 'Nama', type: 'string' },
    { name: 'alamat', label: 'Alamat', type: 'text', hideColumn: true },
    { name: 'telepon', label: 'Telepon', type: 'string' },
    { name: 'pekerjaan', label: 'Pekerjaan', type: 'string', hideColumn: true },
    { name: 'npwpd', label: 'NPWPD', type: 'string', hideColumn: true },
    { name: 'jenis_kelamin', label: 'Jenis Kelamin', type: 'enum', enum: ['Laki-laki', 'Perempuan'] },
    { name: 'tempat_lahir', label: 'Tempat Lahir', type: 'string' },
    { name: 'tgl_lahir', label: 'Tanggal Lahir', type: 'date' },
    { name: 'agama', label: 'Agama', type: 'enum', enum: ['Islam', 'Katolik', 'Protestan', 'Hindu', 'Budha', 'Konghucu', 'Kepercayaan'], hideColumn: true },
    { name: 'hubungan', label: 'Hubungan', type: 'string' },
  ]
}

export const Jenazah: Model = {
  name: 'jenazah',
  title: 'Jenazah',
  fields: [
    { name: 'nama', label: 'Nama', type: 'string', searchable: true },
    { name: 'alamat', label: 'Alamat', type: 'text', hideColumn: true },
    { name: 'jenis_kelamin', label: 'Jenis Kelamin', type: 'enum', enum: ['Laki-laki', 'Perempuan'] },
    { name: 'tempat_lahir', label: 'Tempat Lahir', type: 'string' },
    { name: 'tgl_lahir', label: 'Tanggal Lahir', type: 'date' },
    { name: 'tgl_wafat', label: 'Tanggal Wafat', type: 'date' },
    { name: 'pekerjaan', label: 'Pekerjaan', type: 'string', hideColumn: true },
    { name: 'agama', label: 'Agama', type: 'enum', enum: ['Islam', 'Katolik', 'Protestan', 'Hindu', 'Budha', 'Konghucu', 'Kepercayaan'] },
    { name: 'darah', label: 'Golongan Darah', type: 'enum', enum: ['A', 'B', 'AB', 'O'], hideColumn: true },
    { name: 'ahli_waris', label: 'Ahli Waris', type: 'array', model: AhliWaris, },
    { name: 'tgl_daftar', label: 'Tgl. Pendaftaran', type: 'date', defaultValue: new Date() },
    { name: 'tpu', label: 'TPU', type: 'foreign_key', foreignKey: { model: 'tpus', field: 'nama' } },
    { name: 'nomor_makam', label: 'Nomor Makam', type: 'foreign_key', foreignKey: { model: 'makam', field: 'no', filter: 'tpu' } },
  ],
}