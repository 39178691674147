export const Noname: Model = {
  name: 'noname',
  title: 'Noname',
  fields: [
    { name: 'no', label: 'Nomor', type: 'string' },
    { name: 'jenis_kelamin', label: 'Jenis Kelamin', type: 'enum', enum: ['Laki-laki', 'Perempuan'] },
    { name: 'tgl', label: 'Tanggal Pendataan', type: 'date', defaultValue: new Date() },
    { name: 'keterangan', label: 'Keterangan', type: 'text' },
    { name: 'tpu', label: 'TPU', type: 'foreign_key', foreignKey: { model: 'tpus', field: 'nama' } },
    { name: 'nomor_makam', label: 'Nomor Makam', type: 'foreign_key', foreignKey: { model: 'makam', field: 'no', filter: 'tpu' } },
  ],
}