const UserModel: Model = {
  name: 'users',
  title: 'User',
  description: 'User list to access application',
  fields: [
    { name: 'name', label: 'Nama', type: 'string', searchable: true },
    { name: 'email', label: 'Email', type: 'string', searchable: true },
    { name: 'nip', label: 'NIP', type: 'string' },
    {
      name: 'jabatan',
      label: 'Jabatan',
      type: 'foreign_key',
      foreignKey: { model: 'permissions', field: 'name' },
    },
    { name: 'alamat', label: 'Alamat', type: 'text', hideColumn: true },
    { name: 'telepon', label: 'Telepon', type: 'string' },
    { name: 'keterangan', label: 'Keterangan', type: 'text', hideColumn: true },
    { name: 'dev', label: 'Developer', type: 'boolean' },
    {
      name: 'register',
      label: 'Register URL',
      type: 'string',
      omitEmpty: true,
      readOnly: true,
      hideColumn: true,
    },    
  ]
};

export default UserModel;
