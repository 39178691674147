const Makam: Model = {
  name: 'makam',
  title: 'Makam',
  fields: [
    { name: 'no', label: 'No. Makam', type: 'number', searchable: true },
    { name: 'tpu', label: 'TPU', type: 'foreign_key', foreignKey: { model: 'tpus', field: 'nama' } },
  ],
};

export default Makam;
