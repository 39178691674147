const PermissionModel: Model = {
  name: 'permissions',
  title: 'Permission',
  description: 'Permissions',
  fields: [
    { name: 'name', label: 'Jabatan', type: 'string', searchable: true },
    { name: 'register', label: 'Menambah Data', type: 'boolean' },
    { name: 'user', label: 'Menambah User', type: 'boolean' },
    { name: 'master', label: 'Mengubah Master Data', type: 'boolean' },
    { name: 'sign', label: 'Penandatangan ', type: 'boolean' },
  ],
};

export default PermissionModel;
