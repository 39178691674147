const TPU: Model = {
  name: 'tpus',
  title: 'TPU',
  fields: [
    { name: 'kode', label: 'Kode TPU', type: 'string', searchable: true },
    { name: 'nama', label: 'Nama TPU', type: 'string', searchable: true },
    { name: 'blok', label: 'Blok', type: 'string' },
    { name: 'kuota', label: 'Kuota', type: 'number' },
    { name: 'sisa', label: 'Sisa', type: 'number' },
  ],
};

export default TPU;
