import MasterPage from './modules/master/MasterPage';
import { PendaftaranPage } from './modules/pendaftaran/PendaftaranPage';
import { TransaksiPage } from './modules/transaksi/TransaksiPage';
import UserRoutePage from './modules/users';

export const routes: Route[] = [
  { href: '/master', page: MasterPage },
  { href: '/pendaftaran', page: PendaftaranPage },
  { href: '/transaksi', page: TransaksiPage },
  { href: '/users', page: UserRoutePage },
];
