import React from 'react';
import BasisApp from './basis/BasisApp';
import { routes } from './routes';
import { sidebar } from './sidebars';

const App: React.FC = () => {
  return (
    <BasisApp
      routes={routes}
      serverUrl="https://siputer-b27ccws5oq-et.a.run.app"
      sidebar={sidebar}
    />
  );
};

export default App;
